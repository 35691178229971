import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/SEO"
import Blog from "components/Blog"

const PostTemplate = props => {
  const post = props.data.wordpressPost
  return (
    <Layout location={props.location} navigation>
      <SEO isBlogPost="true" seo={post.seo} />
      <Blog details={post} />
    </Layout>
  )
}

export default PostTemplate

export const postQuery = graphql`
  query PostById($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    wordpressPost(id: { eq: $id }) {
      author {
        emailaddress
        jobTitle
        name
        image {
          alt_text
          id
          title
        }
      }
      slug
      categories {
        name
        slug
      }
      slug
      id
      wordpress_id
      content
      seo {
        title
        siteName
        description
        image {
          src {
            localFile {
              childImageSharp {
                fixed(width: 1280, height: 853, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      date(formatString: "DD MMMM, YYYY")
      hero {
        author
        date
        image {
          title
          src {
            alt_text
            title
            source_url
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                  srcSetBreakpoints: [400, 1024, 1280, 1921]
                  maxWidth: 1921
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        readingTime
        title
      }
      author {
        name
        image {
          alt_text
          title
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
